<template>
  <div class="footer-layout">
    <footer class="footer">
      <div class="footer-left">
        <img class="footer-left-img" src="../assets/Wonder-Core-logo-w.png" />
        <div class="footer-left-title">
          万达康 Wonder Core
          引领智能健康生活，逐步实现智慧运动蓝图，激励人们追求更健康的未来。
        </div>
      </div>
      <div class="footer-center">
        <p class="footer-center-title">关于万达康</p>
        <button class="footer-center-content">关于公司</button>
        <button class="footer-center-content">联络我们</button>
        <!-- <button class="footer-center-content">隐私权政策</button> -->
      </div>
    </footer>
    <a class="bottom-Copyright" href="https://beian.miit.gov.cn/">备案号 粤ICP备2022049624号</a>
  </div>
</template>
<script type="text/javascript">
export default {
  name: "FooterView",
};
</script>
<style scoped>
.footer-layout {
  background-color: #212121;
  height: 300px;
  display: flex;
  flex-direction: column;
}
.footer {
  flex-basis: auto;
  display: flex;
  direction: row;
  flex-grow: 1; /*索取剩余空间*/
}

.footer-left {
  width: 240px;
  height: 100%;
  display: flex;
  margin-left: 15px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer-left-img {
  width: 170px;
}
.footer-left-title {
  font: 15px;
  color: #888888;
  margin-top: 30px;
  text-align: left;
}

.footer-center {
  width: 240px;
  height: 100%;
  display: flex;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 40px;
}
.footer-center-title {
  font: 15px;
  color: white;
  margin-top: 30px;
  text-align: left;
  height: 8px;
  font-weight: 500;
}
.footer-center-content {
  font: 15px;
  color: #888888;
  margin-top: 30px;
  text-align: left;
  background: transparent;
  border: none;
  height: 6px;
}
.footer-center-content:hover {
  color: white;
}
.bottom-Copyright {
  position: relative;
  bottom: 20px;
  left: 0%;
  right: 0%;
  color: #888888;
  font-size: 12px;
  flex: initial;
}
</style>
