<template>
  <div>
    <div class="head">
      <img src="../assets/productsDetail-top.jpeg" class="img" />
      <div class="dec-layout">
        <p class="title">Best Training Plan</p>
        <p class="content">产品</p>
      </div>
    </div>
    <div class="content-layout">
      <TabView :titles="titles"></TabView>
    </div>
  </div>
</template>
<script type="text/javascript">
//   import axios from "axios";
import TabView from "./TabView.vue";

export default {
  components: {TabView},
  data() {
    return {
      titles: [
        { name: "全部", page: [] },
        { name: "健身器材", page: [] },
        { name: "运动配件", page: [] },
        { name: "其他", page: [] },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>
.head {
  height: 300px;
  position: relative;
}
.img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.dec-layout {
  position: absolute;
  bottom: 0%;
  left: 0%;
  right: 0%;
  top: 0%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  height: 40px;
}
.content {
  color: #fff;
  font-size: 20px;
  font-weight: 200;
  height: 40px;
}
.contentLayout {
  color: #fff;
}
/* .head {
  position: absolute;
  z-index: 100;
  font-size: 26rpx;
  top: 0%;
  left: 0%;
  right: 0%;
  transform: translate(0, 0);
} */
</style>
