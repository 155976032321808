<template>
  <div class="title-content-layout">
    <P class="title-content-0">{{ this.title }}</P>
    <p class="title-content-1">{{ this.content0 }}</p>
    <p class="title-content-2">
      {{ this.content1 }}
    </p>
    <p class="title-content-2">
      {{ this.content2 }}
    </p>
  </div>
</template>
<script type="text/javascript">
export default {
  name: "TitleContent",
  props: {
    title: String,
    content0: String,
    content1: String,
    content2: String,
  },
  data() {
    return {
      // titleStr: this.title,
      // content0Str: this.content0,
      // content1Str: this.content1,
    };
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style>
.title-content-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.title-content-0 {
  color: white;
  font-size: 28px;
  font-weight: 500;
}
.title-content-1 {
  color: #c6d500;
  font-size: 16px;
}
.title-content-2 {
  color: white;
  font-size: 16px;
  line-height: 8px;

}
</style>
