<template>
    <div class="head">
         <div>
        <ul style="list-style-type: none">
          <li v-for="title in titles" :key="title.name" v-on:click='jumpPage(title.page)'>
            <button class="item" >
              {{ title.name }}
            </button>
          </li>
        </ul>
      </div>
     
    </div>
  </template>
  <script type="text/javascript">
  export default {
    name: "TabView",
    props: {
      titles: [],
    },
    data() {
      return {
        currentView: "",
        activeName: "second",
      };
    },
    methods: {
      jumpPage(page) {
        alert("page");
        this.$emit("jumpPage", page);
      },
    },
  };
  </script>
  <style scoped>
  .head {
    padding: 0px 0;
    background-color: black;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    padding-right: 40px;
    padding-left: 40px;
  }
  
  .item {
    color: #ccc;
    background: transparent;
    border: none;
    margin-right: 30px;
  }
  
  .item:hover {
    color: #c6d500;
  }
  .expanded {
    flex-grow: 1;
  }
  .img {
    width: 120px;
    object-fit: contain;
  }
  li {
    display: inline;
    line-height: 40px;
    float: left;
  }
  </style>
  