<template>
  <div class="index">
    <HeaderView
      @jumpPage="jumpPageCallback"
      class="head"
      :titles="titles"
    ></HeaderView>
    <component :is="currentView"></component>
    <FooterView></FooterView>
  </div>
</template>

<script type="text/javascript">
import MainPage from "@/components/MainPage.vue";
import ProductPage from "@/components/ProductPage.vue";
import AboutPage from "@/components/AboutPage.vue";
import HeaderView from "@/components/HeaderView.vue";
import FooterView from "@/components/FooterView.vue";
import ContactPage from "@/components/ContactPage.vue";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      currentView: "",
      activeName: "second",
      titles: [
        {
          name: "主页",
          page: "MainPage",
        },
      
        {
          name: "联系我们",
          page: "ContactPage",
        },
      ],
    };
  },
  components: {
    HeaderView,
    MainPage,
    ProductPage,
    AboutPage,
    FooterView,
    ContactPage,
  },
  mounted() {
    this.currentView = "MainPage";
  },
  methods: {
    onItemClick(tab) {
      console.log(tab.name);
      this.currentView = tab.name;
    },
    jumpPageCallback(event) {
      this.currentView = event;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.index {
  position: relative;
}

.head {
  position: absolute;
  z-index: 100;
  font-size: 26rpx;
  top: 0%;
  left: 0%;
  right: 0%;
  transform: translate(0, 0);
}
</style>
