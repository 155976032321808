<template>
  <div class="head">
    <!-- <button class="item" v-on:click="jumpPage('ContactPage')">联系我们</button>
    <button class="item" v-on:click="jumpPage('ProductPage')">产品</button>
    <button class="item" v-on:click="jumpPage('MainPage')">主页</button> -->
    <div>
      <ul style="list-style-type: none">
        <li v-for="title in titles" :key="title.name" v-on:click='jumpPage(title.page)'>
          <button class="item" >
            {{ title.name }}
          </button>
        </li>
      </ul>
    </div>
    <span class="expanded"></span>
    <img class="img" src="../assets/Wonder-Core-logo-w.png" />
  </div>
</template>
<script type="text/javascript">
export default {
  name: "HeaderView",
  props: {
    titles: [],
  },
  data() {
    return {
      currentView: "",
      activeName: "second",
    };
  },
  methods: {
    jumpPage(page) {
      this.$emit("jumpPage", page);
    },
  },
};
</script>
<style scoped>
.head {
  padding: 20px 0;
  /* background-color: black; */
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 40px;
  padding-left: 40px;
}

.item {
  color: #ccc;
  background: transparent;
  border: none;
  margin-right: 30px;
}

.item:hover {
  color: #c6d500;
}
.expanded {
  flex-grow: 1;
}
.img {
  width: 120px;
  object-fit: contain;
}
li {
  display: inline;
  line-height: 40px;
  float: left;
}
</style>
