<template>
  <div>
    <SwipeView></SwipeView>

    <div class="item-layout">
      <img class="item-img" src="../assets/index-web-bg-03-1.jpeg" />
      <TitleContent
        class="item-title-right"
        title="引领智慧健康生活"
        content0="高端研发力 x 释放运动潜能"
        content1="致力打造专业健身器材，已申请超过200项专利"
        content2="在科技转型的新世代，让运动融入每个人的生活"
      ></TitleContent>
    </div>
    <div class="item-layout">
      <img class="item-img" src="../assets/index-web-bg-04.jpeg" />
      <TitleContent
        class="item-title-left"
        title="专属运动APP"
        content0="原创课程 专属训练"
        content1="提供无限次数观看课程"
        content2="给予最专业的师资与训练规划"
      ></TitleContent>
    </div>
    <div class="item-layout">
      <img class="item-img" src="../assets/index-web-bg-05.jpeg" />
      <TitleContent
        class="item-title-right"
        title="巨星级原创影音制作"
        content0="专业拍摄团队 高端摄影设备"
        content1="采用业界高规配备与高水平摄影团队"
        content2="吸引众多A咖明星：冯迪索等人租借拍摄"
      ></TitleContent>
    </div>
    <div class="item-layout">
      <img class="item-img" src="../assets/index-web-bg-09.jpeg" />
      <TitleContent
        class="item-title-left"
        title="多国专利认证"
        content0="坚强研发技术，备受世界肯定"
        content1="Wonder Core 专注于研发健身产品
严密设计并务求"
        content2="产品达到最高标准,专业实力荣获各国多项专利的认可"
      ></TitleContent>
    </div>
    <div class="item-layout">
      <img class="item-img" src="../assets/index-web-bg-10.jpeg" />
      <TitleContent
        class="item-title-right"
        title="畅销多国 好评不断"
        content0="运动，从现在开始！"
        content1="Wonder Core 独创商品获得网红强力推荐"
        content2="热卖商品已售逾千万台 外销市场持续扩大"
      ></TitleContent>
    </div>
    <img class="item-img" src="../assets/index-web-bg-12.jpeg" />
  </div>
</template>
<style>
.main {
  height: 2000px;
  width: 140px;
  background-color: #f00;
}
.item-layout {
  position: relative;
}
.item-img {
  background-color: #f00;
  width: 100%;
  object-fit: contain;
}

.item-title-right {
  right: 100px;
  bottom: 0%;
  top: 0%;
  position: absolute;
  z-index: 2;
}
.item-title-left {
  left: 100px;
  bottom: 0%;
  top: 0%;
  position: absolute;
  z-index: 2;
}
</style>
<script>
import TitleContent from "./TitleContent.vue";
import SwipeView from "./SwipeView.vue";
export default {
  components: { TitleContent, SwipeView },
};
</script>
