<template>
  <div>
    <div class="head">
      <img src="../assets/contact-us-top.jpeg" class="img" />
      <div class="dec-layout">
        <p class="title">联络我们</p>
        <p class="content">给您提供最好的服务</p>
      </div>
    </div>
    <div class="layout-content">
      <div class="left-layout">
        <p class="left-title">与我们保持联系</p>
        <p class="left-content">
          如果您对我们提供的产品及服务有任何问题或意见，请透过Email与我们联络。
        </p>
        <div class="row-parent">
          <el-input v-model="name" placeholder="请输入名称"></el-input>
          <span class="span-w"></span>
          <el-input v-model="phone" placeholder="请输入手机"></el-input>
        </div>
        <span class="span-h"></span>
        <el-input v-model="email" placeholder="请输入Email"></el-input>
        <span class="span-h"></span>
        <el-input v-model="title" placeholder="请输入标题"></el-input>
        <span class="span-h"></span>
        <el-input v-model="content" placeholder="请输入内容"></el-input>
        <span class="span-h"></span>
        <button class="btn" v-on:click="submit">发送</button>
        <div class="sendTip" v-show="isSendTipStr">{{ sendTipStr }}</div>
      </div>
      <div class="right-layout">
        <p class="left-title">客户服务</p>
        <p class="title-sm">服务专线</p>
        <p class="content-sm">0754-81888951</p>
        <p class="title-sm">服务时间</p>
        <p class="content-sm">週一至周五 9:00 – 18:00</p>
        <p class="title-sm">公司地址</p>
        <p class="content-sm">
          广东省汕头市龙湖区珠池工业小区珠业一街3号B座三楼之三
        </p>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import axios from "axios";

export default {
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      title: "",
      content: "",
      sendTipStr: "dada",
      isSendTipStr: false,
    };
  },

  methods: {
    submit() {
      axios
        .post("http://127.0.0.1:8083/fit/website/feekback", {
          name: "郑泽锋",
          phone: "15622202910",
          title: "关于速度问题",
          content: "启动速度太慢",
          email: "603702817@qq.com",
        })
        .then((res) => {
          this.isSendTipStr = true;
          console.log(res.data); //打印请求数据
          if (res.data.code == 0) {
            this.sendTipStr = "留言发送成功";
          } else {
            this.sendTipStr = res.data.msg;
          }
        });
    },
  },
};
</script>

<style scoped>
.head {
  height: 300px;
  position: relative;
}
.img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.dec-layout {
  position: absolute;
  bottom: 0%;
  left: 0%;
  right: 0%;
  top: 0%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  height: 40px;
}
.content {
  color: #fff;
  font-size: 20px;
  font-weight: 200;
  height: 40px;
}
.left-layout {
  flex: 1;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.right-layout {
  flex: 1;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.layout-content {
  height: 500px;
  display: flex;
  margin-top: 70px;
  flex-direction: row;
}
.left-title {
  color: #000000;
  font-weight: 500;
  font-size: 25px;
}
.left-content {
  color: #000;
  font-size: 15px;
  text-align: left;
}
.row-parent {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: space-between;
}
.btn {
  padding: 10px 30px;
  background-color: #000;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
}
.btn:hover {
  background-color: #000;
  color: #fff;
}

.span-w {
  width: 20px;
}
.span-h {
  height: 20px;
}

.title-sm {
  font-size: 16px;
  color: black;
  font-weight: 500;
  margin-top: 40px;
  height: 13px;
}

.content-sm {
  font-size: 14px;
  color: black;
  height: 13px;
}

.sendTip {
  padding: 7px 20px;
  margin-top: 20px;
  border: green solid;
  border-radius: 5px;
  font-size: 14px;
}
</style>
